/* #GRID
------------------------------------------- */
$grid-offset-step: 5px;
$mobile-device-width-sm: 768px;
$mobile-device-width: 991px;
$laptop-device-width: 1199px;

/* #COLORS
------------------------------------------- */
$theme-primary-color: #121212;
$theme-secondary-color: #244a87;
$theme-color-blue: #0078ff;

/* #FONTS
------------------------------------------- */
$theme-main-font: 'NimbusSansNovus', Arial, Helvetica, sans-serif;
