@import '../base/variables';
@import '../components/buttons';

// #MODAL
//----------------------------------
$modal-overlay-bg-before: rgba(0, 0, 0, 0);
$modal-overlay-bg: rgba(0, 0, 0, 0.3);

.bi-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &__btn-close {
    position: absolute;
    top: $grid-offset-step * 4;
    right: $grid-offset-step * 4;

    @extend .bi-btn--flat;
  }

  &__btn-close-icon {
    width: 16px;
    height: 16px;
  }
}

.bi-modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $modal-overlay-bg-before;
}

.ReactModal__Overlay {
  transition: background-color 150ms ease-in-out;

  &--after-open {
    background-color: $modal-overlay-bg;
  }

  &--before-close {
    background-color: $modal-overlay-bg-before;
  }
}

.ReactModal__Overlay--after-open {
  z-index: 9;
}

.bi-modal--scale {
  transition: transform 150ms ease-in-out;
  transform: translate(-50%, -50%) scale(0);
}

.bi-modal--scale.ReactModal__Content {
  &--after-open {
    transform: translate(-50%, -50%) scale(1);
  }

  &--before-close {
    transform: translate(-50%, -50%) scale(0);
  }
}
