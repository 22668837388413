@import './variables.scss';

.u-word-wrap {
  word-break: break-all;
}

.u-word-wrap--ellipsis {
  display: block;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media screen and (max-width: $mobile-device-width) {
  .u-word-wrap {
    display: block;
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .u-word-wrap--full {
    white-space: normal;
  }
}

.base-text {
  font-size: 18px;
  font-weight: 500;
  margin: $grid-offset-step * 10 $grid-offset-step * 15;

  @media screen and (max-width: $laptop-device-width) {
    margin: ($grid-offset-step * 5) ($grid-offset-step * 5);
  }

  @media screen and (max-width: $mobile-device-width) {
    margin: ($grid-offset-step * 5) ($grid-offset-step * 3);
  }
}
