.ergo-loader {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  svg {
    width: 80px;
    height: 80px;
  }
  path:first-child {
    stroke-width: 4px;
    stroke-dashoffset: 10;
    stroke-dasharray: 20;
    fill: black;
    animation: dash2 2s linear infinite normal;
    transform-origin: center center;
    display: inline-block;
    transform-box: fill-box;

    @keyframes dash2 {
      100% {
        transform: rotate(180deg);
      }
    }
  }
}
